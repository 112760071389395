import styled, {css} from 'styled-components'
import bg1 from '../../assets/images/bg1.jpg'
import bg2 from '../../assets/images/bg2.jpg'
import bg3 from '../../assets/images/bg3.jpg'
import bg4 from '../../assets/images/bg4.jpg'
import bgm from '../../assets/images/bgm.jpg'
import "./_style.css";

export const SectionPricipal = styled.section` 

    width:100%;
    display: flex;

    .zap{
        display:flex;
        align-items:center;
        justify-content:center;
        position:fixed;
        width:60px;
        height:60px;
        bottom:30px;
        right:30px;
        background-color: #fff;
        color:#25d366;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
        z-index:50;



        svg{
            font-size:36px;
            font-weight:bold;
        }
    }


    background-color: #fff;

    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    
    @media screen and (max-width: 1100px) {
        height: auto;
        }

`

export const Header = styled.header` 

    height: 90px;
    width: 100%;
    position: fixed;
    z-index:51;
    background-color: #fff;
    display: flex;
    align-items:center;
    justify-content: center;
    box-shadow: 0 0 10px rgb(136 136 136 / 10%);
    @media screen and (max-width: 1100px) {
        display: none;
        }    

    .header-inner{
        height: 90px;
        width: 100%;
        max-width: 1200px;
        background-color: #fff;
        display: flex;
        align-items:center;
        position: fixed;
        
        .header-inner1{
            width: calc(100% - 300px);
            height:100%;
            display: flex;
            align-items:center;
            .logo-top{
                height: 50px;
            }
        }

        .header-inner2{
            width: 300px;
            height:100%;
            background-color: #25DC8C;
            display: flex;
            justify-content: flex-end;
            align-items:center;
            font-family: 'Poppins', sans-serif;   
            

            a{
                text-decoration: none;
                color: #25DC8C;
                font-weight:bold;
                padding: 10px;
                transition: all 0.2s ease-in-out;
                background-color: #FFF;
                margin: 0 20px;
                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 600;
                margin: 0 25px;
                font-size: 14px;
                width: 70%;
                text-align:CENTER;
                border: solid 1px transparent;

                &:hover{
                    color:#FFF;
                    background-color: #25DC8C;
                    border-radius:4px;
                    border: solid 1px #fff;
                }

            }

        }

    }

    .header-inner3{
            width: 100%;
            height:100%;
            background-color: #fff;
            float:right;

            .header-inner4{
            width: 50%;
            height:100%;
            background-color: #25DC8C;
            float:right;
    }
    }

`

export const HeaderMobile = styled.header`

display: none;
width:100%;
position:fixed;
z-index:60;
box-shadow: 0 0 10px rgb(136 136 136 / 15%);

.HeaderMobile-Inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255,255,255,1);
    height:72px;
    width:100%;
    padding:15px;

    img{
        max-width:45px;
    }

    button{
        background-color:transparent;
        border: solid 1px #25DC8C;
        border-radius:4px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            color: #25DC8C;
            font-size:25px;
        }

        .HI01M-Menu{
                        opacity:1;
                        display: flex;
                        transition: 0.3s ease-in-out;

                        ${props => props.stateMenu && css`
                            opacity:0;
                            display: none;
                        `}
                    }  

                    .HI01M-Close{
                        opacity:0;
                        display: none;
                        transition: 0.3s ease-in-out;

                        ${props => props.stateMenu && css`
                            opacity:1;
                            display: flex;
                        `}                        
                    }         
    }
}


@media screen and (max-width: 1100px) {
        display: flex;
        }    
` 


export const Menu = styled.nav`

width:100%;
display:flex;
justify-content:flex-end;
font-family: 'Poppins', sans-serif;   


ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top:3px;
    }
    li {
    display: inline;
    margin-right:10px;
    text-transform:uppercase;
    font-weight: 600;
    padding: 38px 5px;
    margin: 0 5px;
    font-size: 14px;


    }

    a{
        text-decoration: none;
        color: #362725;
        padding: 10px 5px;
        transition: all 0.2s ease-in-out;

        &:hover{
            color:#25DC8C;
            border-radius:4px;
        }

    }
` 

export const MenuModal = styled.nav`

height:100%;
width:100%;
background-color: rgba(0,0,0,0.2);
transition: 0.5s ease-in-out;
z-index:-10;
position:fixed;
align-items:center;
justify-content:flex-end;
opacity:0;
padding-top:72px;

display: none;
@media screen and (max-width: 1100px) {
        display: block;
        }    

${props => props.stateMenu && css`
    opacity:1;
    z-index:55;
`}

.MenuModal-Inner{
    height:100%;
    width:100%; 
    background-color:#fff;
    transition: 0.3s ease-in-out;
    z-index:-10;  
    margin-top: 1500px;
    position:fixed;
    

    ${props => props.stateMenu && css`
        margin-top: 0;
        z-index:56;
    `}    

    .MenuModal-Inner-Itens{

        display: flex;
        align-items:center;
        justify-content: center;
        text-align: center;

        ul{
                list-style-type: none;
                margin: 0;
                padding: 0;
                font-size:15px;
                

                li{
                    margin: 5px 0 ;                    
                    padding: 10px 10px;
                    border-bottom: solid 1px #25DC8C;

                    &:last-child{
                        border-bottom: none;
                    }

                    a{
                        color:#25DC8C;
                        text-decoration:none;
                        text-transform:uppercase;
                        font-size:25px;

                    }
                }

            }

    }

    .header-inner2{
            height:30%;
            display: flex;
            justify-content: center;
            align-items:center;
            font-family: 'Poppins', sans-serif;   
            

            a{
                padding:15px;
                text-decoration: none;
                color: #fff;
                font-weight:bold;
                transition: all 0.2s ease-in-out;
                background-color: #25DC8C;
                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 600;
                font-size: 18px;
                width: 70%;
                text-align:CENTER;
                border: solid 1px transparent;

                &:hover{
                    color:#25DC8C;
                    background-color: #fff;
                    border-radius:4px;
                    border: solid 1px #25DC8C;
                }

            }

        }
}

`

export const BannerMobile = styled.section`
display:flex;
width:100%;
height:400px;
padding-top:72px;

background-color: transparent;
background-position: center; 
background-repeat: no-repeat; 
background-size: cover; 
background-image: url(${bgm});

display: none;

@media screen and (max-width: 1100px) {
        display: flex;
        }    


.BannerMobile-Inner{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    text-align: center;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    color:#fff;
    padding:30px;
    

    h2{
        font-size:25px;
    }

    .span-title{
        color:#fff;
        font-size:40px;
        letter-spacing: 3px;
        font-weight:bold;
    }

    p{
        font-size:22px;
    }

    a{
        margin-top:35px;
        padding:15px;
        text-decoration: none;
        color: #fff;
        font-weight:bold;
        transition: all 0.2s ease-in-out;
        background-color: #25DC8C;
        border-radius:4px;                
        text-transform:uppercase;
        font-weight: 600;
        font-size: 16px;
        width: 80%;
        text-align:CENTER;
        border: solid 1px transparent;

        &:hover{
            color:#25DC8C;
            background-color: #fff;
            border-radius:4px;
            border: solid 1px #25DC8C;
        }

    }
    
}

` 

export const Banner = styled.section` 

width:100%;
height:100%;
display: flex;
align-items:center;
justify-content: center;
margin-top:90px;
@media screen and (max-width: 1100px) {
        display: none;
        }    


.banner-inner{
    width:100%;
    height:100%;
    max-width: 1200px;
    display: flex;
    


    .banner-inner1{
        width:50%;
        height:100%;
        display: flex;
        align-items:center;
        padding-right:50px;
        font-family: 'Poppins', sans-serif;  
        margin-top: -35px;
        z-index:11;

        .span-title{
            color:#25DC8C;
            text-transform:uppercase;
            font-weight: 600;
            font-size: 80px;
        }

        h2{

            color: #362725;
            font-weight: 500;
            font-size: 27.2px;
            line-height: 1.1;
            margin-bottom:20px;
            margin-top:-15px;

            span{
                font-weight: 700;
            }
            

        }

        p{
            margin-bottom:40px;
        }
        
        a{
                text-decoration: none;
                color: #25DC8C;
                font-weight:bold;
                padding: 15px 35px;
                transition: all 0.2s ease-in-out;

                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 500;
                font-size: 18px;
                text-align:center;
                border: solid 2px #25DC8C;
                transition:  500ms ease-in-out;
                animation: pulse 1.5s infinite;

                &:hover{
                    color:#FFF;
                    background-color: #25DC8C;
                    border-radius:4px;
                    border: solid 2px #fff;
                }

            }

         
        

    }

    .banner-inner2{

        width:50%;
        height:100%;
        z-index:10;
        
        

        .banner-inner3{

            background-color: #25DC8C;
            width: 300px;
            height:100%;
            float: right;
        }

        .banner-fotos{
            height:calc(100% - 90px);
            position: absolute;
            float: right;




            .banner-fotos-inner{
                height:calc(100% - 90px);
                min-height: 420px;
                background-color: transparent;
                background-position: center; 
                background-repeat: no-repeat; 
                background-size: cover; 
                /* background-image: url(${bg1}); */
                /* border: solid 8px #fff;
                box-shadow: 0 0 10px rgb(136 136 136 / 20%); */
                width: 44vw;
                border-radius: 1%;
                margin-top:10px;

                .hehe{
                    height:100% !important;

                    ul{
                        height:100% !important;

                        li{
                            height: 100% !important;
                        }
                    }
                }


                .banner-fotos-inner-1{
                    display:flex;
                    height:100%;
                    width:100%;
                    background-color: red;
                    border-radius:4px;
                    background-color: transparent;
                    background-position: center; 
                    background-repeat: no-repeat; 
                    background-size: cover; 
                    background-image: url(${bg1});
                    
                }

                .banner-fotos-inner-2{
                    display:flex;
                    height:100%;
                    width:100%;
                    background-color: blue;
                    border-radius:4px;
                    background-color: transparent;
                    background-position: center; 
                    background-repeat: no-repeat; 
                    background-size: cover; 
                    background-image: url(${bg2});
                    
                }

                .banner-fotos-inner-3{
                    display:flex;
                    height:100%;
                    width:100%;
                    background-color: green;
                    border-radius:6px;
                    background-color: transparent;
                    background-position: center; 
                    background-repeat: no-repeat; 
                    background-size: cover; 
                    background-image: url(${bg3});                    
                }

                .banner-fotos-inner-4{
                    display:flex;
                    height:100%;
                    width:100%;
                    background-color: green;
                    border-radius:6px;
                    background-color: transparent;
                    background-position: center; 
                    background-repeat: no-repeat; 
                    background-size: cover; 
                    background-image: url(${bg4});                    
                }                

                

            }
        }

    }
}


.banner-inner4{

        width: 100%;
        height:calc(100% - 90px);
        position: absolute;
        float: right;
        display: flex;
        justify-content: flex-end;
        z-index:0;

        .banner-inner5{
            background-color: #25DC8C;
            width: 25%;
            height:100%;
            position: absolute;
            float: right;
            z-index:0;

    }

    }



`


export const SectionComoFunciona = styled.section`  

display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
height:500px;
width:100%;
z-index:15;

@media screen and (max-width: 1100px) {
            padding:15px;
            height:auto;
        }  


    .SCF-Title{
        width:100%;
        display: flex;
        align-items:center;
        justify-content: center;
        margin-top:100px;
        font-family: 'Poppins', sans-serif;  
        color:#505478;

        @media screen and (max-width: 1100px) {
            h1{
                font-weight:bold;
                font-weight: 500;
                font-size:18px;
            }
            margin-top:10px;

        }          
    }
    h1{
        text-align:left;
        width:100%;
        max-width:1200px;     
        
    }


    .SCF-Inner {
        display: flex;
        justify-content:space-between;
        padding: 50px 0px;
        height:350px;
        width:100%;
        max-width: 1200px;       

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            height:auto;
            padding: 10px 0px;
        }  

            .SCF-Inner-item{
                height:100%;
                width:100%;
                margin: 10px;
                align-items:center;
                justify-content: center;
                background-color:#fff;
                box-shadow: 0 -33px 51px rgb(209 209 209 / 16%);
                border-radius:4px;
                transition: all .3s ease-out 0s;
                padding:25px;

                @media screen and (max-width: 1100px) {
                    margin:10px 0;
                }                  

                &:hover{
                    box-shadow: 0 -33px 51px rgb(180 180 180 / 16%);
                }

                &:hover div svg{
                    border-radius:50%;
                }

                &:last-child{
                    margin-right: 0px;
                    @media screen and (max-width: 1100px) {
                    margin-bottom:30px;
                    }                      
                } 

                &:first-child{
                    margin-left: 0px;
                } 

                .SCF-Inner-item-1{
                    height: 25%;
                    display:flex;
                    align-items: center;
                

                    svg{
                        font-size:45px;
                        padding:10px;
                        background-image:linear-gradient(#a8ff78 0%, #25DC8C 100%);
                        color:#fff;
                        border-radius:4px;
                        transition: all .3s ease-out 0s;

                    }
                }

                .SCF-Inner-item-2{

                    color: #505478;
                    font-family: 'Poppins', sans-serif;  

                    h3{
                        margin-top: 20px;
                        margin-bottom: 5px;
                        font-size: 18px;
                        font-weight: 600;                                                 
                    }

                    p{
                        font-size: 14px;
                        font-weight: 300;    
                    }

                }


            }

    }

    .SCF-Btn{
        margin-bottom:100px;
        a{
                text-decoration: none;
                color: #25DC8C;
                font-weight:bold;
                padding: 15px;
                transition: all 0.2s ease-in-out;
                background-color: #FFF;
                margin: 0 20px;
                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 600;
                margin: 0 25px;
                font-size: 14px;
                width: 70%;
                text-align:CENTER;
                border: solid 1px #25DC8C;

                &:hover{
                    color:#FFF;
                    background-color: #25DC8C;
                    border-radius:4px;
                    border: solid 1px #fff;
                }
               
        }
    }

`

export const SectionCupom = styled.section` 

display: flex;
align-items:center;
justify-content: center;
background: #a8ff78;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #78ffd6, #a8ff78); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
display: flex;
width:100%;

@media screen and (max-width: 1100px) {
        margin-top:-45px;
        padding: 30px 15px;
    }     

.SC-Inner{
    background-color:#fff;
    display: flex;
    justify-content: space-between;
    width:100%;
    max-width: 1200px;
    margin:50px;
    padding:50px;
    border-radius:10px;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content:center;
        align-items: center;
        margin:15px;
        padding:35px 15px;
    }        

    .cupom-text{
        width:100%;
        max-width: 630px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        

        .cupom-text-1{
            color: #505478;
            
            font-family: 'Poppins', sans-serif;  
            h1{
                font-size: 22px;
                font-weight: 600;
                margin-bottom:20px;
            }

            p{
                font-size: 16px;
                font-weight: 300;   
            }
        }

        .cupom-text-2{
            text-align: right;
            margin-top:30px;
            @media screen and (max-width: 1100px) {
                text-align: center;
            }                        
            a{
                text-decoration: none;
                color: #25DC8C;
                font-weight:bold;
                padding: 15px;
                transition: all 0.2s ease-in-out;
                background-color: #FFF;
                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 600;
                font-size: 14px;
                width: 70%;
                text-align:CENTER;
                border: solid 1px #25DC8C;

                &:hover{
                    color:#FFF;
                    background-color: #25DC8C;
                    border-radius:4px;
                    border: solid 1px #fff;
                }

                @media screen and (max-width: 1100px) {
                    width: 100%;
                    display: flex;
                    justify-content:center;
                    align-items:center;
                }           

            }
        }
        
    }
}



.cardWrap {
  width: 27em;
  color: #fff;
  font-family: sans-serif;

  @media screen and (max-width: 1100px) {
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    margin-bottom:30px;
    }        
}



.card {
  background: linear-gradient(to bottom, #25DC8C 0%, #25DC8C 26%, #fff 26%, #ecedef 100%);
  height: 11em;
  float: left;
  position: relative;
  padding: 1em;

}

.cardLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 16em;
  @media screen and (max-width: 1100px) {
    width: 14em;
    }    

    h1{
        position: absolute;
    }

}

.cardRight {
  width: 7.5em;
  @media screen and (max-width: 1100px) {
    width: 5.5em;
    }    
  border-left: .18em dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: .9em;
    height: .9em;
    background: #fff;
    border-radius: 50%;
    left: -.5em;
  }
  &:before {
    top: -.4em;
  }
  &:after {
  bottom: -.4em;
  }

  .logo{
      text-align: center;
      margin-top:-5px;
      margin-bottom:25px;
  }
}

h1 {
  font-size: 1.1em;
  margin-top: 0;
  span {
    font-weight: normal;
  }
}

.title, .name, .seat, .time {
  text-transform: uppercase;
  font-weight: normal;
  h2 {
    font-size: .9em;
    color: #525252;
    margin: 0;
      
   }
  span {
    font-size: .7em;
    color: #a2aeae;
  }
}

.title {
  margin: 2em 0 0 0;
  margin-top:55px;
}

.name, .seat {
  margin: .7em 0 0 0;

}

.time {
  margin: .7em 0 0 1em;
}

.seat, .time {
  float: left;
}

.eye {
  position: relative;
  width: 2em;
  height: 1.5em;
  background: #fff;
  margin: 0 auto;
  border-radius: 1em/0.6em;
  z-index: 1;
  &:before, &:after {
    content:"";
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    width: 1em;
    height: 1em;
    background: #25DC8C;
    z-index: 2;
    left: 8px;
    top: 4px;
  }
  &:after {
  width: .5em;
  height: .5em;
  background: #fff;
  z-index: 3;
  left: 12px;
  top: 8px;
  }
}

.number {
  text-align: center;
  text-transform: uppercase;
  h3 {
    color: #25DC8C;
    margin: .4em 0 0 0;
    font-size: 2.5em;

    @media screen and (max-width: 1100px) {
        font-size: 2em;
    } 
    
  }
  span {
    display: block;
    margin: 5px 5px 0 0;
    color: #a2aeae;
    font-size: 2em;
    @media screen and (max-width: 1100px) {
        font-size: 1.6em;
    }     
  }
}

.barcode {
  height: 2em;
  width: 0;
  margin: 1.2em 0 0 .8em;
  box-shadow: 1px 0 0 1px #343434,
  5px 0 0 1px #343434,
  10px 0 0 1px #343434,
  11px 0 0 1px #343434,
  15px 0 0 1px #343434,
  18px 0 0 1px #343434,
  22px 0 0 1px #343434,
  23px 0 0 1px #343434,
  26px 0 0 1px #343434,
  30px 0 0 1px #343434,
  35px 0 0 1px #343434,
  37px 0 0 1px #343434,
  41px 0 0 1px #343434,
  44px 0 0 1px #343434,
  47px 0 0 1px #343434,
  51px 0 0 1px #343434,
  56px 0 0 1px #343434,
  59px 0 0 1px #343434,
  64px 0 0 1px #343434,
  68px 0 0 1px #343434,
  72px 0 0 1px #343434,
  74px 0 0 1px #343434,
  77px 0 0 1px #343434,
  81px 0 0 1px #343434;
}


` 

export const SectionPromo = styled.section`  

display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
height:auto;
width:100%;
border-bottom: 1px solid #EEE;


    .SP-Title{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        margin-top:100px;
        font-family: 'Poppins', sans-serif;  
        color:#505478;
    }
    h1{
        text-align:center;
        width:100%;
        max-width:1200px;     
        
    }


    .SP-Inner {
        display: flex;
        justify-content:space-between;
        padding: 50px 0px;
        height:350px;
        width:100%;
        max-width: 1200px;       

        @media screen and (max-width: 1100px) {
            flex-direction: column;
            align-items:center;
            height:auto;
            padding:20px;
        }           

            .SP-Inner-item{
                height:100%;
                width:100%;
                margin: 10px;
                align-items:center;
                justify-content: center;
                background-color:#fff;
                box-shadow: 0 -33px 51px rgb(209 209 209 / 30%);
                border-radius:4px;
                transition: all .3s ease-out 0s;
                padding:25px;

                @media screen and (max-width: 1100px) {
                    margin: 105px 0;
                    height:150px;   
                    width:95%;              
                }                    

                &:hover{
                    box-shadow: 0 -33px 51px rgb(180 180 180 / 16%);
                }

                &:hover div svg{
                    border-radius:50%;
                }

                &:last-child{
                    margin-right: 0px;
                } 

                &:first-child{
                    margin-left: 0px;
                } 

                .SP-Inner-item-1{
                    height: 25%;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                
                }

                .SP-Inner-item-2{

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    color: #505478;
                    font-family: 'Poppins', sans-serif;  
                    text-align: center;

                    h3{
                        margin-top: 50px;
                        margin-bottom: 5px;
                        font-size: 16px;
                        font-weight: 600;    
                        min-height: 80px;        
                        @media screen and (max-width: 1100px) {
                            margin-top: 80px;   
                            margin-bottom: -15px;         
                        }                                                                  
                    }

                    p{
                        font-size: 19px;
                        font-weight: 300;    
                        color:#25DC8C;
                        font-weight:bold;

                        span{
                            text-decoration: line-through;
                            color:#ccc;
                            font-weight:normal;
                        }
                    }

                    svg{
                        color:#e9ba17;
                    }

                    a{
                        text-decoration: none;
                        color: #25DC8C;
                        font-weight:bold;
                        padding: 10px;
                        margin-top:20px;
                        transition: all 0.2s ease-in-out;
                        background-color: #FFF;
                        border-radius:4px;                
                        text-transform:uppercase;
                        font-weight: 600;
                        font-size: 14px;
                        width: 70%;
                        text-align:CENTER;
                        border: solid 1px #25DC8C;
                        

                        &:hover{
                            color:#FFF;
                            background-color: #25DC8C;
                            border-radius:4px;
                            border: solid 1px #fff;
                        }
                    }

                }


            }

    }

    .SP-Btn{
        margin:80px 0;
        margin-top:120px;
        @media screen and (max-width: 1100px) {    
            width: 100%;
            padding:0 25px;
            margin-top:70px;
                }

        a{
                text-decoration: none;
                color: #fff;
                font-weight:bold;
                padding: 15px;
                transition: all 0.2s ease-in-out;
                background-color: #25DC8C;
                border-radius:4px;                
                text-transform:uppercase;
                font-weight: 600;
                margin: 0 25px;
                font-size: 14px;
                width: 70%;
                text-align:CENTER;
                border: solid 1px #25DC8C;

                &:hover{
                    color:#25DC8C;
                    background-color: #fff;
                    border-radius:4px;
                    border: solid 1px #25DC8C;
                }
                @media screen and (max-width: 1100px) {    
                    display: flex;
                    justify-content:center;
                    align-items:center;  
                    margin: 0 ;
                    width: 100% !important;  
                }                  
        }
    }

`

