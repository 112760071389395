import styled from 'styled-components'

export const ContainerFooter = styled.footer`

width:100%;
height: auto;
background:#eee;
display:flex;
flex-direction:column;
align-items:center;
padding: 30px 60px;

@media screen and (max-width: 1100px) {
    height: 100%;
}  

hr{
    border-top: 1px solid #eee;
    margin: 30px 0;
    }

.max-wid{
    width:100%;
    max-width:1200px;
}

.container-1{
    display:flex;
    justify-content:space-between;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        h3{
            margin: 10px 0;
        }
    }    

    div{
        h3{


            a{
                margin:15px 0;
                color:#505050;
                font-size: 16px;           
                text-decoration:none;     
            }
        }

        p{
            margin:10px 0;
            color:#9a9a9a;
        }
    }


    .footer-logo{

        img{
            margin:10px;
        }

        form{
            display:flex;
        }        

        a{
            border:solid 1px rgba(245,245,245);
            padding: 10px;
            border-radius: 50%;
            background:#fff;
            display:flex;
            align-items:center;
            justify-content: center;
            height:45px;
            width:45px;
            transition: 0.3s ease-in-out;
            
            box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px
            rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
            margin:5px;
            color:#bababa;

            svg{
                font-size:20px;
            }

            &:hover{
                background-color:#25DC8C;
                color:#fff;
            }
        }

        p{
            margin: 15px 10px;
            color:#9a9a9a;
            font-size:15px;
            line-height: 1.75;
        }
    }

    .footer-links{
        margin-top:20px;
    }
}

.container-2{
    display:flex;
    justify-content:space-between;
}

.cnpj{
    display:flex;
    justify-content:center;
    align-items:center;
    padding:30px;
    color:#9a9a9a;
    font-size:14px;
    background-color:#eee;
    width:100%;
}


`
