/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import { ContainerFooter } from './style'
import logo2 from '../../assets/images/logo-nufit-2.png'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaPinterest } from 'react-icons/fa'
import pays from '../../assets/images/pay.jpg'
import seguro from '../../assets/images/site-seguro.png'

class Footer extends Component {

    render() {

        return (

            <ContainerFooter>

                <div className='max-wid'>

                    <div className='container-1'>
                        <div className='footer-logo'>
                            <img src={logo2} alt='logo' />
                            <form>
                                <a href="https://www.facebook.com/nufitoficial" target="_blank"><FaFacebookF /></a>
                                <a href="https://twitter.com/nufitoficial" target="_blank"><FaTwitter /></a>
                                <a href="https://www.linkedin.com/company/nufitoficial" target="_blank"><FaLinkedinIn /></a>
                                <a href="https://www.instagram.com/nufitoficial/" target="_blank"><FaInstagram /></a>
                                <a href="https://www.instagram.com/nufitoficial/" target="_blank"><FaPinterest /></a>
                            </form>
                            <p>© 2020 - Nufit. Todos os direitos reservados</p>
                        </div>

                        <div>
                            <h3><a href="https://app.nufit.com.br">CARDÁPIO</a></h3>

                        </div>

                        <div>
                            <h3><a href="#link1">COMO FUNCIONA</a></h3>

                        </div>

                        <div>
                            <h3><a href="#link2">DESCONTO</a></h3>

                        </div>
                        <div>
                            <h3><a href="/">BLOG</a></h3>

                        </div>
                        <div>
                            <h3><a href="https://wa.me/5511910965338?text=" target="_blank">FALE COM A GENTE</a></h3>

                        </div>
                    </div>
                    <hr />
                    <div className='container-2'>
                        <div><a href="https://transparencyreport.google.com/safe-browsing/search?url=www.nufit.com.br"><img src={seguro} alt='seguro' /></a></div>
                        {/* <div className='container-2-pay'><img src={pays} alt='pagamentos' /></div> */}
                    </div>

                    <div className='cnpj'>
                        Nufit Comercio de Alimentos Ltda. - CNPJ 34.062.412/0001-78
                    </div>

                </div>

            </ContainerFooter>
        )
    }

}

export default Footer