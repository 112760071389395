import {createGlobalStyle, css} from 'styled-components'
export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{
    margin:0;
    padding:0;
    outline:0;
    box-sizing:border-box;
}

body, html{
  height: 100%;
  scroll-behavior: smooth;
  
  ${props => (props.viewLoginModal || props.viewAddItem ||  props.viewLocalizaModal ||  props.viewSBCarrinho || props.viewSBPesquisa) &&  css` 
        overflow: hidden !important;
    `}

}

body{
    background: #fff;
    -webkit-font-smoothing: antialiased;
    
}


body, input, button {
    font-family: 'Roboto', sans-serif;
}

#root{
    margin: 0 auto;
    height: 100%;
}

button{
    cursor: pointer;
}

`