/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import {PesquisaComp} from './style'
import logo from '../../assets/images/logo_color.png'

class Pesquisa extends Component {

    render() {

        return (
            <>

                <PesquisaComp>

                    <img src={logo} alt='logo'/>
                    <h1>Pesquisa de Satisfação</h1>
                    <a href='https://pt.surveymonkey.com/r/BTB33N3'>INCIAR</a>           
                    <p>© 2020 - Nufit. Todos os direitos reservados</p>         

                </PesquisaComp>


            </>

        )


    }

}

export default Pesquisa