import styled from 'styled-components'

export const PesquisaComp = styled.section` 

background-color:#25DC8C;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
height:100vh;
color: #FFFFFF;

a{
    text-decoration: none;
    color: #25DC8C;
    font-weight:bold;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    background-color: #FFF;
    margin: 0 20px;
    border-radius:4px;                
    text-transform:uppercase;
    font-weight: 600;
    margin: 0 25px;
    font-size: 18px;
    width: 70%;
    text-align:CENTER;
    border: solid 1px transparent;

    &:hover{
        color:#FFF;
        background-color: #25DC8C;
        border-radius:4px;
        border: solid 1px #fff;
    }

}

img{
    width: 90px;
}

` 
