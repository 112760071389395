/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import Carousel from 'nuka-carousel';
import { SectionPricipal, Header, HeaderMobile, Banner, BannerMobile, Menu, MenuModal, SectionComoFunciona, SectionCupom, SectionPromo } from './style'
import logo from '../../assets/images/logo_nufit.png'
import logob from '../../assets/images/logo_white.png'
import logo_color from '../../assets/images/logo_color.png'
import prato1 from '../../assets/images/prato1.png'
import prato2 from '../../assets/images/prato2.png'
import prato3 from '../../assets/images/prato3.png'
import prato4 from '../../assets/images/prato4.png'
import minilogo from '../../assets/images/mini_logo.png'
import { AiOutlineWhatsApp, AiFillStar } from 'react-icons/ai'
import { MdRestaurantMenu } from 'react-icons/md'
import { GiHotMeal } from 'react-icons/gi'
import { BiFoodMenu, BiWinkSmile } from 'react-icons/bi'
import Footer from '../../components/Footer'
import { FiMenu, FiX } from 'react-icons/fi'


class Home extends Component {

    state = {
        menuView: false,
    }

    handleMenu = e => {
        this.setState({
            menuView: !this.state.menuView,
            loginView: false
        })

        if (!this.state.menuView) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }

    handlCloseMenu = e => {
        console.log(e.target)
        if (e.target.id === 'modal') {
            this.setState({
                menuView: false
            })
            this.setState({
                loginView: false
            })
        }

        if (!this.state.menuView) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }

    render() {

        return (
            <>
                <SectionPricipal>


                    <a className="zap" href="https://wa.me/5511910965338?text=" target="_blank">
                        <AiOutlineWhatsApp />
                    </a>

                    <Header>

                        <div className="header-inner">

                            <div className="header-inner1">
                                <img className="logo-top" src={logo} alt='logo' />

                                <Menu>

                                    <ul>
                                        <li><a href="https://app.nufit.com.br">Cardápio</a></li>
                                        <li><a href="#link1">Como Funciona</a></li>
                                        <li><a href="#link2">DESCONTO</a></li>
                                        <li><a href="/">Blog</a></li>
                                        <li><a href="https://wa.me/5511910965338?text=" target="_blank">Contato</a></li>
                                    </ul>


                                </Menu>

                            </div>

                            <div className="header-inner2">

                                <a href="https://app.nufit.com.br/entrar">ACESSAR MINHA CONTA</a>

                            </div>

                        </div>

                        <div className="header-inner3">

                            <div className="header-inner4">

                            </div>

                        </div>


                    </Header>

                    <HeaderMobile stateMenu={this.state.menuView}>

                        <div className="HeaderMobile-Inner">
                            <img src={logob} alt='logo' />
                            <button onClick={this.handleMenu}> <FiMenu className="HI01M-Menu" /> <FiX className="HI01M-Close" /> </button>
                        </div>

                    </HeaderMobile>

                    <MenuModal stateMenu={this.state.menuView}>

                        <div className="MenuModal-Inner">

                            <div className="MenuModal-Inner-Itens">
                                <ul>
                                    <li><a onClick={this.handleMenu} href="https://app.nufit.com.br">Cardápio</a></li>
                                    <li><a onClick={this.handleMenu} href="#link1">Como Funciona</a></li>
                                    <li><a onClick={this.handleMenu} href="#link2">DESCONTO</a></li>
                                    <li><a onClick={this.handleMenu} href="/">Blog</a></li>
                                    <li><a onClick={this.handleMenu} href="/">Contato</a></li>
                                </ul>
                            </div>

                            <div className="header-inner2">

                                <a href="https://app.nufit.com.br/entrar">ACESSAR MINHA CONTA</a>

                            </div>

                        </div>

                    </MenuModal>


                    <BannerMobile>
                        <div className="BannerMobile-Inner">
                            <span className="span-title">2020 PASSOU</span>
                            <h2>E o ano de <span>mudar</span> seus <span>hábitos</span> chegou</h2>
                            <a href="https://app.nufit.com.br/">IR PARA O CARDÁPIO</a>

                        </div>

                    </BannerMobile>

                    <Banner>

                        <div className="banner-inner">


                            <div className="banner-inner1">

                                <div>
                                    <span className="span-title">2020 PASSOU</span>
                                    <h2>E o ano de <span>mudar</span> seus <span>hábitos</span> chegou</h2>
                                    <p>Conheça o cardápio prático e delicioso que vai transformar a sua alimentação. Nossos pratos foram pensados pra você criar o hábito de comer bem, nutrindo seu corpo e mente.</p>
                                    <a href="https://app.nufit.com.br/">IR PARA O CARDÁPIO</a>
                                </div>

                            </div>

                            <div className="banner-inner2">

                                <div className="banner-fotos">
                                    <div className="banner-fotos-inner">
                                        <Carousel transitionMode="scroll" wrapAround={true} className='hehe' minHeight='100%' withoutControls={true} autoplay={true} autoplayreverse={true}>

                                            <div className="banner-fotos-inner-1"> </div>
                                            <div className="banner-fotos-inner-2"> </div>
                                            <div className="banner-fotos-inner-3"> </div>
                                            <div className="banner-fotos-inner-4"> </div>


                                        </Carousel>
                                    </div>
                                </div>

                                <div className="banner-inner3"> </div>


                            </div>


                        </div>

                        <div className="banner-inner4">
                            <div className="banner-inner5">

                            </div>
                        </div>




                    </Banner>

                </SectionPricipal>

                 <SectionComoFunciona id='link1'>
                    <div className="SCF-Title">
                        <h1>Com a gente é assim:</h1>
                    </div>


                    <div className="SCF-Inner">


                        <div className="SCF-Inner-item">
                            <div className="SCF-Inner-item-1">
                                <BiFoodMenu />
                            </div>


                            <div className="SCF-Inner-item-2">
                                <h3>1. VOCÊ ESCOLHE</h3>
                                <p>
                                    Escolha os seus pratos favoritos no site ou através de nosso whatsapp e agende o melhor dia e horário para receber seu pedido.
                                </p>
                            </div>

                        </div>

                        <div className="SCF-Inner-item">
                            <div className="SCF-Inner-item-1">
                                <MdRestaurantMenu />
                            </div>


                            <div className="SCF-Inner-item-2">
                                <h3>2. NÓS PREPARAMOS</h3>
                                <p>
                                    Nosso chef cozinha tudo e nós te entregamos em marmitas ou porções individuais embaladas a vácuo ultracongeladas.
                                </p>
                            </div>
                        </div>

                        <div className="SCF-Inner-item">
                            <div className="SCF-Inner-item-1">
                                <GiHotMeal />
                            </div>


                            <div className="SCF-Inner-item-2">
                                <h3>3. É SÓ ESQUENTAR</h3>
                                <p>
                                    Esquente no micro-ondas ou banho mariacordo com o tempo indicado na embalagem e em seguida é só baorear.
                                </p>
                            </div>
                        </div>

                        <div className="SCF-Inner-item">
                            <div className="SCF-Inner-item-1">
                                <BiWinkSmile />
                            </div>


                            <div className="SCF-Inner-item-2">
                                <h3>4. E APROVEITAR</h3>
                                <p>
                                    Aproveite melhor seu tempo e tenha mais saúde, energia, foco, disposição e uma digestão incrível.
                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="SCF-Btn" id='link2'>
                        <a href="https://app.nufit.com.br">QUERO VER O CARDÁPIO</a>
                    </div>


                </SectionComoFunciona>




                <SectionCupom>

                    <div className="SC-Inner">
                        <a href="https://app.nufit.com.br/nufit/pedidos?code=VEMNUFIT">
                        <div className="cardWrap">
                            <div className="card cardLeft">
                                <h1>CUMPOM <span>#VEMNUFIT</span></h1>
                                <div className="title">
                                    <h2>20% de desconto</h2>
                                    <span>na sua primeira compra</span>
                                </div>
                                <div className="name">
                                    <h2>+ 1 brinde</h2>
                                    <span>afinal, você merece!</span>
                                </div>

                            </div>
                            <div className="card cardRight">
                                <div className="logo"> <img src={minilogo} alt="logo" /> </div>
                                <div className="number">
                                    <h3>20%</h3>
                                    <span>OFF</span>
                                </div>
                            </div>

                        </div>
                        </a>

                        <div className="cupom-text">
                            <div className="cupom-text-1">
                                <h1> APROVEITE NOSSO CUPOM ESPECIAL DE LANÇAMENTO! </h1>
                                <p>Sem mi-mi-mi ou regras chatas! É isso mesmo, clique no cupom ao lado e garanta 20% de desconto na sua primeira compra, válido para todos os produtos! E como você é especial, também vamos enviar um super brinde para celebrar o início do nosso relacionamento! 💚</p>
                            </div>

                            <div className="cupom-text-2">
                                <a href="https://app.nufit.com.br/nufit/pedidos?code=VEMNUFIT">USAR MEU CUPOM AGORA</a>
                            </div>

                        </div>

                    </div>


                </SectionCupom>

                <SectionPromo>
                    <div className="SP-Title">
                        <h1>Preços especiais para você aproveitar</h1>
                        <p> Confira os nossos pratos com até 50% de desconto </p>
                    </div>



                    <div className="SP-Inner">


                        <div className="SP-Inner-item">
                            <div className="SP-Inner-item-1">
                                <img src={prato1} alt='prato1' />
                            </div>

                            <div className="SP-Inner-item-2">
                                <h3>Hambúrguer + Mandioquinha em cubos + Mix de legumes</h3>
                                <p>
                                    <span>R$ 22,90</span> R$16,90
                                </p>
                                <div> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> </div>
                                <a href="https://app.nufit.com.br/nufit/pedidos/14494">VER OFERTA</a>
                            </div>

                        </div>

                        <div className="SP-Inner-item">
                            <div className="SP-Inner-item-1">
                                <img src={prato2} alt='prato2' />
                            </div>

                            <div className="SP-Inner-item-2">
                                <h3>Carne de Panela + Purê de mandioquinha + Brócolis</h3>
                                <p>
                                    <span>R$ 18,90</span> R$12,90
                                </p>
                                <div> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> </div>
                                <a href="https://app.nufit.com.br/nufit/pedidos/14504">VER OFERTA</a>
                            </div>

                        </div>

                        <div className="SP-Inner-item">
                            <div className="SP-Inner-item-1">
                                <img src={prato3} alt='prato3' />
                            </div>

                            <div className="SP-Inner-item-2">
                                <h3>Frango desfiado + Purê de batata doce + Mix de legumes</h3>
                                <p>
                                    <span>R$ 14,90</span> R$9,90
                                </p>
                                <div> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> </div>
                                <a href="https://app.nufit.com.br/nufit/pedidos/14487">VER OFERTA</a>
                            </div>

                        </div>
                        <div className="SP-Inner-item">
                            <div className="SP-Inner-item-1">
                                <img src={prato4} alt='prato4' />
                            </div>

                            <div className="SP-Inner-item-2">
                                <h3>Filé de frango + Purê de mandioquinha + Mix de legumes</h3>
                                <p>
                                    <span>R$ 15,90</span> R$9,90
                                </p>
                                <div> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> </div>
                                <a href="https://app.nufit.com.br/nufit/pedidos/14490">VER OFERTA</a>
                            </div>

                        </div> 

                    </div>

                    <div className="SP-Btn">
                        <a href="https://app.nufit.com.br">VER TODAS AS OFERTAS</a>
                    </div>


                </SectionPromo>

                <Footer /> 

            </>

        )


    }

}

export default Home