import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Home from './pages/Home'
import Pesquisa from './pages/Pesquisa'

function Routes() {
    return(
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/qa/'  component={Pesquisa} />
        </Switch>
    )
}

export default Routes